import React, { useEffect, useMemo, useState } from "react";
import { tr, useData } from "../i18n";
import styles from "./index.module.scss";
import imgFictishop from "./fictishop.png";
import imgSmoothify from "./smoothify.png";
import imgArchive from "./archive.png";
import imgYuzu from "./yuzu.jpg";
var Status;
(function (Status) {
    Status[Status["Writing"] = 0] = "Writing";
    Status[Status["Removing"] = 1] = "Removing";
    Status[Status["Waiting"] = 2] = "Waiting";
})(Status || (Status = {}));
export var Code = function (props) {
    var data = useData();
    var cursorChar1 = "▮";
    var cursorChar2 = "\xa0";
    var _a = useState("I build awesome apps with"), code = _a[0], setCode = _a[1];
    var _b = useState(Status.Writing), status = _b[0], setStatus = _b[1];
    var _c = useState(" "), blinkCursor = _c[0], setBlinkCursor = _c[1];
    var endings = [
        "React",
        "NodeJS",
        "Rust",
        "TypeScript",
        "Java",
        "OCaml",
        "C / C++",
        "PHP",
        "Python",
        "Redux",
        "Django",
    ].map(function (ending) { return " " + ending; });
    var _d = useState(0), endingIndex = _d[0], setEndingIndex = _d[1];
    var ending = useMemo(function () { return endings[endingIndex % endings.length]; }, [endingIndex]);
    var waitingTime = 20;
    var _e = useState(0), waiting = _e[0], setWaiting = _e[1];
    var _f = useState(0), charIndex = _f[0], setCharIndex = _f[1];
    var _g = useState(), archive = _g[0], setArchive = _g[1];
    var _h = useState(), iwpo = _h[0], setIwpo = _h[1];
    var _j = useState(), yuzuPoc = _j[0], setYuzuPoc = _j[1];
    var _k = useState(), fictishop = _k[0], setFictishop = _k[1];
    var _l = useState(), smoothify = _l[0], setSmoothify = _l[1];
    var _m = useState(), lemmings = _m[0], setLemmings = _m[1];
    var _o = useState(), portSAT = _o[0], setPortSAT = _o[1];
    useEffect(function () {
        if (!data)
            return;
        var load = function (setter, name) {
            setter(data
                .projects
                .content
                .find(function (p) { return p.name === name; }));
        };
        load(setIwpo, "I wanna play online");
        load(setFictishop, "Fictishop");
        load(setSmoothify, "Smoothify");
        load(setArchive, "Fangame Archive");
        load(setYuzuPoc, "yuzu-poc");
        load(setLemmings, "Lemmings");
        load(setPortSAT, "PORTSAT");
    }, [data]);
    useEffect(function () {
        var timeout = setTimeout(function () {
            switch (status) {
                case Status.Writing:
                    setCode(function (code) { return code + ending.charAt(charIndex); });
                    setCharIndex(function (i) { return i + 1; });
                    if (charIndex === ending.length) {
                        setWaiting(waitingTime);
                        setStatus(Status.Waiting);
                    }
                    break;
                case Status.Removing:
                    if (charIndex <= 1) {
                        setEndingIndex(function (i) { return i + 1; });
                        setCharIndex(0);
                        setStatus(Status.Writing);
                    }
                    else {
                        setCode(function (code) { return code.slice(0, code.length - 1); });
                        setCharIndex(function (i) { return i - 1; });
                    }
                    break;
                case Status.Waiting:
                    setWaiting(function (w) { return w - 1; });
                    if (waiting <= 0)
                        setStatus(Status.Removing);
                    break;
            }
        }, 100);
        return function () { return clearTimeout(timeout); };
    }, [status, waiting, charIndex, endingIndex]);
    useEffect(function () {
        var interval = setInterval(function () {
            setBlinkCursor(function (cursor) { return cursor === cursorChar1 ? cursorChar2 : cursorChar1; });
        }, 530);
        return function () { return clearInterval(interval); };
    }, []);
    useEffect(function () {
        var fadeinElements = Array.from(document.querySelectorAll("." + styles.fadein));
        var appearOnScroll = new IntersectionObserver(function (entries, observer) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (!entry.isIntersecting)
                    continue;
                var element = entry.target;
                element.style.opacity = "1";
                element.style.setProperty("--translate", "translateX(0)");
                observer.unobserve(element);
            }
        }, {
            rootMargin: "0px 0px -250px 0px",
        });
        for (var _i = 0, fadeinElements_1 = fadeinElements; _i < fadeinElements_1.length; _i++) {
            var element = fadeinElements_1[_i];
            appearOnScroll.observe(element);
        }
    }, []);
    var cursor = useMemo(function () {
        if (status === Status.Waiting)
            return blinkCursor;
        return cursorChar1;
    }, [blinkCursor, status]);
    var renderVideo = function (id, right) {
        return React.createElement("iframe", { className: [styles.fadein, right ? styles.right : styles.left].join(" "), src: "https://www.youtube.com/embed/" + id, frameBorder: "0", "custom-right": right.toString(), allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true });
    };
    var renderProjects = function () { return React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.video },
            React.createElement("img", { className: [styles.fadein, styles.left].join(" "), src: imgFictishop, onClick: function () { return window.open(fictishop === null || fictishop === void 0 ? void 0 : fictishop.website, "_blank"); } }),
            React.createElement("p", { className: [styles.fadein, styles.right].join(" ") }, tr(fictishop === null || fictishop === void 0 ? void 0 : fictishop.description))),
        React.createElement("div", { className: styles.video, "custom-right": "true" },
            React.createElement("p", { className: [styles.fadein, styles.left].join(" ") }, tr(smoothify === null || smoothify === void 0 ? void 0 : smoothify.description)),
            React.createElement("img", { className: [styles.fadein, styles.right].join(" "), "custom-right": "true", src: imgSmoothify, onClick: function () { return window.open(smoothify === null || smoothify === void 0 ? void 0 : smoothify.website, "_blank"); } })),
        React.createElement("div", { className: styles.video },
            React.createElement("img", { className: [styles.fadein, styles.left].join(" "), src: imgArchive, onClick: function () {
                    var _a;
                    return window.open((_a = archive === null || archive === void 0 ? void 0 : archive.website) !== null && _a !== void 0 ? _a : "", "_blank");
                } }),
            React.createElement("p", { className: [styles.fadein, styles.right].join(" ") }, tr(archive === null || archive === void 0 ? void 0 : archive.description))),
        React.createElement("div", { className: styles.video, "custom-right": "true" },
            React.createElement("p", { className: [styles.fadein, styles.left].join(" ") }, tr(yuzuPoc === null || yuzuPoc === void 0 ? void 0 : yuzuPoc.description)),
            React.createElement("img", { className: [styles.fadein, styles.right].join(" "), "custom-right": "true", src: imgYuzu, onClick: function () { return window.open(yuzuPoc === null || yuzuPoc === void 0 ? void 0 : yuzuPoc.website, "_blank"); } }))); };
    var renderOther = function () { return React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.video, "custom-right": "true" },
            React.createElement("p", { className: [styles.fadein, styles.left].join(" ") }, tr(portSAT === null || portSAT === void 0 ? void 0 : portSAT.description)),
            React.createElement("iframe", { className: [styles.fadein, styles.right].join(" "), "custom-right": "true", src: (portSAT === null || portSAT === void 0 ? void 0 : portSAT.website) + "#toolbar=0", onClick: function () { return window.open(portSAT === null || portSAT === void 0 ? void 0 : portSAT.website, "_blank"); } })),
        React.createElement("div", { className: styles.video },
            renderVideo("5jv1JP_wL0w", false),
            React.createElement("p", { className: [styles.fadein, styles.right].join(" ") }, tr(lemmings === null || lemmings === void 0 ? void 0 : lemmings.description))),
        React.createElement("div", { className: styles.video, "custom-right": "true" },
            React.createElement("p", { className: [styles.fadein, styles.left].join(" ") }, tr(iwpo === null || iwpo === void 0 ? void 0 : iwpo.description)),
            renderVideo("woHeoKBPV9E", true)),
        React.createElement("div", { className: styles.video },
            renderVideo("QRrcwahx-3s", false),
            React.createElement("p", { className: [styles.fadein, styles.right].join(" ") }, data === null || data === void 0 ? void 0 : data.typeTheory.description.map(tr).join(" ")))); };
    return React.createElement("div", { className: styles.container, "custom-other": (props.other === true).toString() },
        props.other ? React.createElement(React.Fragment, null) : React.createElement("div", { className: styles.code }, code + cursor),
        React.createElement("div", { className: styles.title }, props.other
            ? tr(data === null || data === void 0 ? void 0 : data.projects.otherTitle)
            : tr(data === null || data === void 0 ? void 0 : data.projects.title)),
        props.other ? renderOther() : renderProjects());
};
