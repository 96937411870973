import React from "react";
import { Spacer, Waves } from "../spacer";
import { Header } from "../header";
import { About } from "../about";
import { Code } from "../code";
import { Stacks } from "../stacks";
import { Footer } from "../footer";
export var App = function () {
    return React.createElement("div", { style: {
            overflow: "hidden",
        } },
        React.createElement(Header, null),
        React.createElement(Spacer, { waves: Waves.Waves1 }),
        React.createElement(About, null),
        React.createElement(Spacer, { waves: Waves.Waves2 }),
        React.createElement(Stacks, null),
        React.createElement(Spacer, { waves: Waves.Waves3 }),
        React.createElement(Code, null),
        React.createElement(Spacer, { waves: Waves.Waves4 }),
        React.createElement(Code, { other: true }),
        React.createElement(Spacer, { waves: Waves.Waves5 }),
        React.createElement(Footer, null));
};
