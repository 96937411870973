export var setScrollbarColor = function (color) {
    var picker = "body::-webkit-scrollbar-thumb {\n\t\tbackground-color:" + color + ";\n\t}";
    var stylesheet = "<style type=\"text/css\">" + picker + "</style>";
    var id = "custom-stylesheet";
    var getDiv = function () {
        var existingDiv = document.getElementById(id);
        if (existingDiv !== null)
            return existingDiv;
        var newDiv = document.createElement("div");
        newDiv.setAttribute("id", id);
        var head = document.getElementsByTagName("head")[0];
        head.append(newDiv);
        return newDiv;
    };
    var div = getDiv();
    div.innerHTML = stylesheet;
};
export var isDesktop = function () { return window.innerWidth >= 900; };
