// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3w4qDhw5nx3sxCiRER6EXy {\n  color: white;\n  background-color: #202731;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 5rem 0;\n  font-size: 2rem;\n}\n._3w4qDhw5nx3sxCiRER6EXy ._35BqGkoS6DM86e0CDa_8mL {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n}\n._3w4qDhw5nx3sxCiRER6EXy ._35BqGkoS6DM86e0CDa_8mL a {\n  color: white;\n  font-size: 2rem;\n  transition: color 200ms ease-in-out;\n}\n._3w4qDhw5nx3sxCiRER6EXy ._35BqGkoS6DM86e0CDa_8mL a:hover {\n  color: lightgray;\n}", "",{"version":3,"sources":["webpack://./src/footer/index.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;AACD;AAAC;EACC,aAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAEF;AADE;EACC,YAAA;EACA,eAAA;EACA,mCAAA;AAGH;AAFG;EACC,gBAAA;AAIJ","sourcesContent":[".container {\n\tcolor: white;\n\tbackground-color: #202731;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding: 5rem 0;\n\tfont-size: 2rem;\n\t.contact {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\twidth: 100%;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tgap: 2rem;\n\t\ta {\n\t\t\tcolor: white;\n\t\t\tfont-size: 2rem;\n\t\t\ttransition: color 200ms ease-in-out;\n\t\t\t&:hover {\n\t\t\t\tcolor: lightgray;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3w4qDhw5nx3sxCiRER6EXy",
	"contact": "_35BqGkoS6DM86e0CDa_8mL"
};
export default ___CSS_LOADER_EXPORT___;
