import React, { useEffect, useRef } from "react";
import { tr, useData } from "../i18n";
// @ts-ignore
import KUTE from "kute.js";
import styles from "./index.module.scss";
export var Header = function () {
    var data = useData();
    var title = useRef(null);
    useEffect(function () {
        if (!data)
            return;
        var tween = KUTE.fromTo("#blob1", { path: "#blob1" }, { path: "#blob2" }, {
            repeat: Infinity,
            duration: 3000,
            yoyo: true,
            easing: "easingSinusoidalInOut",
        });
        tween.start();
    }, [data]);
    useEffect(function () {
        if (!data)
            return;
        var h1 = title.current;
        if (h1 === null)
            return;
        var i = 0;
        h1.style.position = "relative";
        var transform = function (y) {
            return "translate(0, " + y + "px) skew(-10deg)";
        };
        var name = data.name;
        var _loop_1 = function (letter) {
            var span = document.createElement("span");
            span.innerHTML = letter.replace(/\s/g, "&#160;");
            span.style.position = "relative";
            span.style.transform = transform(100);
            span.style.float = "left";
            span.style.opacity = "0";
            h1.appendChild(span);
            setTimeout(function () {
                span.style.opacity = "1";
                span.style.transform = transform(0);
            }, i * 80 + 200);
            i += 1;
        };
        for (var _i = 0, name_1 = name; _i < name_1.length; _i++) {
            var letter = name_1[_i];
            _loop_1(letter);
        }
    }, [title, data]);
    return React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.title },
            React.createElement("svg", { viewBox: "0 0 960 300", width: "960", height: "500", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", version: "1.1" },
                React.createElement("g", { className: styles.path },
                    React.createElement("path", { id: "blob1", d: "M67.4 -65.8C81.6 -53.3 83.3 -26.6 87 3.7C90.6 33.9 96.2 67.9 82 88.5C67.9 109.2 33.9 116.6 3.4 113.2C-27.1 109.8 -54.2 95.5 -79.2 74.9C-104.2 54.2 -127.1 27.1 -129.7 -2.6C-132.3 -32.3 -114.6 -64.6 -89.6 -77.1C-64.6 -89.6 -32.3 -82.3 -2.8 -79.5C26.6 -76.6 53.3 -78.3 67.4 -65.8", fill: "#BB004B" })),
                React.createElement("g", { transform: "translate(475 140)", style: { visibility: "hidden" } },
                    React.createElement("path", { id: "blob2", d: "M69.7 -60.5C92.7 -46.7 115.3 -23.3 121.3 6C127.4 35.4 116.7 70.7 93.7 86.5C70.7 102.4 35.4 98.7 3.2 95.5C-29 92.3 -58 89.6 -80.1 73.8C-102.3 58 -117.7 29 -112 5.7C-106.3 -17.7 -79.7 -35.4 -57.5 -49.2C-35.4 -63 -17.7 -73 2.8 -75.8C23.3 -78.7 46.7 -74.3 69.7 -60.5" }))),
            React.createElement("h1", { ref: title })), data === null || data === void 0 ? void 0 :
        data.summary.content.slice(0, 1).map(function (p, i) { return React.createElement("p", { key: i }, tr(p)); }));
};
