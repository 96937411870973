import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useData } from "../i18n";
import styles from "./index.module.scss";
export var Footer = function () {
    var data = useData();
    return React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.contact }, [
            [faLinkedin, data === null || data === void 0 ? void 0 : data.linkedin],
            [faGithub, "http://github.com/QuentinJanuel"],
            [faYoutube, "https://www.youtube.com/channel/UCNsWVxjFaJ4z43yKBySWDQw"],
        ].map(function (_a, i) {
            var icon = _a[0], url = _a[1];
            return React.createElement("a", { key: i, href: url, target: "_blank" },
                React.createElement(FontAwesomeIcon, { icon: icon }));
        })),
        React.createElement("p", null,
            "\u00A9 ",
            new Date().getFullYear(),
            " ", data === null || data === void 0 ? void 0 :
            data.name));
};
