import React, { useEffect, useState } from "react";
import { isDesktop } from "../utils";
import { tr, useData } from "../i18n";
import styles from "./index.module.scss";
import HTML5 from "./icons/html5.svg";
import JavaScript from "./icons/javascript.svg";
import CSS3 from "./icons/css3.svg";
import ReactJS from "./icons/reactjs.svg";
import TypeScript from "./icons/typescript.svg";
import Webpack from "./icons/webpack.svg";
import Redux from "./icons/redux.svg";
import NodeJS from "./icons/nodejs.svg";
import Express from "./icons/express.svg";
import MongoDB from "./icons/mongodb.svg";
import PHP from "./icons/php.svg";
import MySQL from "./icons/mysql.svg";
import Apache from "./icons/apache.svg";
export var Stacks = function () {
    var data = useData();
    var _a = useState(0), delta = _a[0], setDelta = _a[1];
    var _b = useState(0), stackIndex = _b[0], setStackIndex = _b[1];
    var stacks = [
        [React.createElement(HTML5, null), React.createElement(JavaScript, null), React.createElement(CSS3, null)],
        [React.createElement(ReactJS, null), React.createElement(TypeScript, null), React.createElement(Webpack, null), React.createElement(Redux, null)],
        [React.createElement(NodeJS, null), React.createElement(Express, null), React.createElement(MongoDB, null)],
        [React.createElement(PHP, null), React.createElement(MySQL, null), React.createElement(Apache, null)],
    ];
    var renderStack = function (stackIndex, d) {
        var stack = stacks[stackIndex];
        return stack.map(function (svg, i) {
            var radius = isDesktop() ? 15 : 7;
            var angle = 2 * Math.PI * i / stack.length + Math.PI / 2 + d;
            var x = radius * Math.cos(angle);
            var y = radius * Math.sin(angle);
            return React.createElement("div", { className: styles.svg, key: i, style: {
                    transform: "translate(" + x + "rem, " + y + "rem)",
                } }, svg);
        });
    };
    useEffect(function () {
        var d = delta;
        var speed = 0;
        var acc = .005;
        var slowdown = 1;
        var interval = setInterval(function () {
            if (Math.abs(speed) > .3) {
                acc *= -1;
                setStackIndex(function (s) { return s + 1; });
            }
            if (Math.abs(speed) < .01)
                slowdown = .01;
            else
                slowdown = 1;
            speed += acc * slowdown;
            d += speed;
            setDelta(d);
        }, 1000 / 60);
        return function () { return clearInterval(interval); };
    }, []);
    return React.createElement("div", { className: styles.container },
        React.createElement("div", null,
            React.createElement("h2", null,
                " ",
                tr(data === null || data === void 0 ? void 0 : data.stack.title)),
            React.createElement("p", null, tr(data === null || data === void 0 ? void 0 : data.stack.description))),
        React.createElement("div", { className: styles.stack }, renderStack(stackIndex % stacks.length, delta)));
};
