// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Raleway:wght@200&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n  font-family: \"M PLUS Rounded 1c\", sans-serif;\n  font-size: 1rem;\n  font-weight: bold;\n  line-height: 1.3;\n  -webkit-tap-highlight-color: transparent;\n}\n\nbody {\n  margin: 0;\n}\nbody::-webkit-scrollbar {\n  width: 20px;\n}\nbody::-webkit-scrollbar-track {\n  background-color: #e4e4e4;\n  border-radius: 100px;\n}\nbody::-webkit-scrollbar-thumb {\n  border-radius: 100px;\n  border: 5px solid transparent;\n  background-clip: content-box;\n  background-color: #C62368;\n}", "",{"version":3,"sources":["webpack://./src/style.scss"],"names":[],"mappings":"AAIA;EACC,sBAAA;EAEA,4CAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,wCAAA;AAFD;;AAKA;EACC,SAAA;AAFD;AAGC;EACC,WAAA;AADF;AAGC;EACC,yBAAA;EACA,oBAAA;AADF;AAGC;EACC,oBAAA;EACA,6BAAA;EACA,4BAAA;EACA,yBAAA;AADF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Raleway:wght@200&display=swap\");\n\n@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');\n\n* {\n\tbox-sizing: border-box;\n\t// font-family: 'Raleway', sans-serif;\n\tfont-family: 'M PLUS Rounded 1c', sans-serif;\n\tfont-size: 1rem;\n\tfont-weight: bold;\n\tline-height: 1.3;\n\t-webkit-tap-highlight-color: transparent;\n}\n\nbody {\n\tmargin: 0;\n\t&::-webkit-scrollbar {\n\t\twidth: 20px;\n\t}\n\t&::-webkit-scrollbar-track {\n\t\tbackground-color: #e4e4e4;\n\t\tborder-radius: 100px;\n\t}\n\t&::-webkit-scrollbar-thumb {\n\t\tborder-radius: 100px;\n\t\tborder: 5px solid transparent;\n\t\tbackground-clip: content-box;\n\t\tbackground-color: #C62368;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
