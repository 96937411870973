import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useData, tr, getPicture } from "../i18n";
import { Loading } from "../loading";
import styles from "./index.module.scss";
export var About = function () {
    var data = useData();
    useEffect(function () {
        if (!data)
            return;
        var fadeinElements = Array.from(document.querySelectorAll("." + styles.fadein));
        var appearOnScroll = new IntersectionObserver(function (entries, observer) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (!entry.isIntersecting)
                    continue;
                var element = entry.target;
                element.style.opacity = "1";
                element.style.setProperty("--translate", "translateX(0)");
                observer.unobserve(element);
            }
        }, {
            rootMargin: "0px 0px -250px 0px",
        });
        for (var _i = 0, fadeinElements_1 = fadeinElements; _i < fadeinElements_1.length; _i++) {
            var element = fadeinElements_1[_i];
            appearOnScroll.observe(element);
        }
    }, [data]);
    // useEffect(() => {
    // 	const section = document.querySelector("." + styles.container);
    // 	if (section === null)
    // 		return;
    // 	const scrollbarObserver = new IntersectionObserver((entries, observer) => {
    // 		for (const entry of entries) {
    // 			if (entry.isIntersecting)
    // 				setScrollbarColor("#202731");
    // 			else
    // 				setScrollbarColor("#C62368");
    // 		}
    // 	}, {
    // 		threshold: .5,
    // 	});
    // 	scrollbarObserver.observe(section);
    // }, []);
    if (!data)
        return React.createElement(Loading, null);
    return React.createElement("div", { className: styles.container },
        React.createElement("div", null,
            React.createElement("img", { src: getPicture(data, "parc-etampes"), className: [
                    styles.profilepic,
                    styles.fadein,
                    styles.left,
                ].join(" ") }),
            React.createElement("div", { className: [
                    styles.about,
                    styles.section,
                    styles.fadein,
                    styles.right,
                ].join(" ") },
                React.createElement("h2", null, tr(data.about.title)),
                data.about.content.map(function (p, i) { return React.createElement("p", { key: i }, tr(p)); }))),
        React.createElement("div", null,
            React.createElement("div", { className: [
                    styles.fadein,
                    styles.left,
                ].join(" ") },
                React.createElement("div", { className: styles.section },
                    React.createElement("h2", null, tr(data.contactMe)),
                    React.createElement("p", null,
                        data.mail,
                        React.createElement("br", null),
                        React.createElement("br", null),
                        tr(data.phone),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        data.address)),
                React.createElement("a", { href: tr(data.resume.url), target: "_blank", className: styles.download },
                    React.createElement(FontAwesomeIcon, { icon: faDownload }),
                    tr(data.resume.downloadButton))),
            React.createElement("iframe", { className: [
                    styles.cv,
                    styles.fadein,
                    styles.right,
                ].join(" "), src: tr(data.resume.url) + "#toolbar=0", onClick: function () { return window.open(tr(data.resume.url), "_blank"); } })));
};
