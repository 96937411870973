import React, { useMemo } from "react";
import waves1 from "./waves1.svgstr";
import waves2 from "./waves2.svgstr";
import waves3 from "./waves3.svgstr";
import waves4 from "./waves4.svgstr";
import waves5 from "./waves5.svgstr";
export var Waves;
(function (Waves) {
    Waves[Waves["Waves1"] = 0] = "Waves1";
    Waves[Waves["Waves2"] = 1] = "Waves2";
    Waves[Waves["Waves3"] = 2] = "Waves3";
    Waves[Waves["Waves4"] = 3] = "Waves4";
    Waves[Waves["Waves5"] = 4] = "Waves5";
})(Waves || (Waves = {}));
export var Spacer = function (props) {
    var getWaves = function (waves) {
        switch (waves) {
            case Waves.Waves1:
                return [waves1, "#202731"];
            case Waves.Waves2:
                return [waves2, "none"];
                ;
            case Waves.Waves3:
                return [waves3, "#FCAF3C"];
                ;
            case Waves.Waves4:
                return [waves4, "none"];
                ;
            case Waves.Waves5:
                return [waves5, "#202731"];
                ;
        }
    };
    var waves = useMemo(function () { return getWaves(props.waves); }, [props.waves]);
    return React.createElement("img", { src: "data:image/svg+xml;base64," + btoa(waves[0]), style: {
            display: "block",
            width: "100%",
            background: waves[1],
        } });
};
